<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
    </div>
    <div>
      <!-- Filter Inputs -->

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <strong>Additional Filter</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CInput v-model="priceMinFilter" label="Price Min" placeholder="20000" />
                </CCol>
                <CCol>
                  <CInput v-model="priceMaxFilter"  label="Price Max" placeholder="500000" />
                </CCol>
             
              </CRow>

              <!-- Row for Button -->
              <CRow class="d-flex justify-content-end mt-3">
                <CCol sm="auto">

                  <CButton
                        size="sm"
                        color="secondary"
                        class="ml-1"
                        @click="onCleanerClick"
                      >
                        Clear
                      </CButton>
                      <CButton
                        size="sm"
                        color="info"
                        class="ml-1"
                        @click="refreshTable"
                      >
                        Search
                      </CButton>


                
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Stock </strong> List </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="computedItems"
                :fields="fields"
                :column-filter="{ external: true, lazy: true }"
                :column-filter-value.sync="columnFilterValue"
                :sorter="{ external: true, resetable: true }"
                :sorter-value.sync="sorterValue" 
                :items-per-page="itemsPerPage"
                :active-page="1"
                hover
                :loading="loading"
          
              >
                <template #show_index="{ index }">
                  <td class="py-2">
                    {{ index + 1 }}
                  </td>
                </template>
                <!-- <template #stockStatus-filter="{ item }">
                  <CMultiSelect
                    :options="stockStatusOptions"
                    :selected="selectedStockStatuses"
                    :selection="true"
                    search
                    searchPlaceholder=""
                    selectionType="tags"
                    @update="setStockStatusFilter"
                  >
                  </CMultiSelect>
                </template> -->
                <!----> <template #stockStatus="{ item }">
                  <td>
                    <CBadge
                      v-if="item.stockStatus.includes('Open')"
                      color="success"
                      >Open</CBadge
                    >
                    <CBadge v-if="item.stockStatus.includes('LOU')" color="info"
                      >LOU</CBadge
                    >
                    <CBadge
                      v-if="item.stockStatus.includes('Booking')"
                      color="warning"
                      >Booking</CBadge
                    >
                    <CBadge v-if="item.stockStatus.includes('Sold')" color="danger"
                      >Sold</CBadge
                    >
                    <CBadge
                      v-if="item.stockStatus.includes('Cancelled')" 
                      color="secondary"
                      >Cancelled</CBadge
                    >
                    <!-- <CBadge
                      v-if="item.stockStatus === 'Available'"
                      color="light"
                      >Available</CBadge
                    > -->
                  </td>
                </template>
                <template #arrivalStatus="{ item }">
                  <td>
                    <CBadge :color="getArrivalBadge(item.arrivalStatus)">
                      {{ item.arrivalStatus }}</CBadge
                    >
                  </td>
                </template> 

                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template>
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <p class="text-muted">Stock No: {{ item.stockNo }}</p>
                      <p class="text-muted">
                        Status: {{ getLatesStockStatusName(item) }}
                      </p>
                      <p class="text-muted">Brand: {{ item.brandName }}</p>
                      <p class="text-muted">Model: {{ item.modelName }}</p>
                      <p class="text-muted">Price: {{ item.price }}</p>
                      <img
                        :src="getImage(item)"
                        alt="Car Image"
                        v-if="getImage(item)"
                        style="max-width: 30%; height: auto; margin-top: 10px"
                      />
                      <div class="button-group">
                        <CButton
                          size="sm"
                          color="info"
                          class="ml-1"
                          @click="onEdit(item)"
                        >
                          Edit
                        </CButton>
                        <CButton
                          size="sm"
                          color="danger"
                          class="ml-1"
                          @click="showDeleteConfirmation(item)"
                        >
                          Delete
                        </CButton>
                      </div>
                    </CCardBody>
                  </CCollapse>
                </template>
              </CDataTable>
              <CPagination
                v-show="pages > 1"
                :pages="pages"
                :active-page.sync="activePage"
                @update:active-page="refreshTable"
              />
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                class="ml-1"
                color="primary"
                @click="addNew"
              >
                Add New
              </CButton>
              <CButton
                type="submit"
                class="ml-1"
                color="primary"
                :href="csvCode"
                download="stocks.csv"
                target="_blank"
              >
                Download
              </CButton>
              <!--               
              <CButton class="ml-1" color="primary" @click="downloadCSV"
                >Download CSV</CButton
              > -->
            </CCardFooter>
          </CCard>
          <CModal
            title="Confirm Delete"
            color="warning"
            :show.sync="warningModal"
            @update:show="onDeleteConfirmation"
          >
            Are you sure you want to delete this {{ itemToDelete.fullName }} ?
          </CModal>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import StockApi from "../../lib/stockApi";

// const items = [];
// const fields = [
//   {
//     key: "show_index",
//     label: "#",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },

//   { key: "stockNo" },
//   // { key: "latestStatus" },
//   {
//     key: "latest_stock_status",
//     _style: "min-width:100px;",
//     label: "Status",
//     filter: false,
//   },
//   { key: "brandName" },
//   { key: "modelName" },
//   { key: "price" },

//   {
//     key: "show_details",
//     label: "",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },
// ];

export default {
  name: "StockList",
  data() {
    return {
      loadedItems: [],
      columnFilterValue: {},
      tableFilterValue: "",
      sorterValue: { column: null, asc: true },
      // Pagination
      activePage: 1,
      itemsPerPage: 20,
      pages: 1,

      stockStatusOptions: [
        { value: "Open", text: "Open" },
        { value: "LOU", text: "LOU" },
        { value: "Booking", text: "Booking" },
        { value: "Sold", text: "Sold" },
        { value: "Cancelled", text: "Cancelled" },
      ],

      // Filter states
      priceMinFilter: "",
      priceMaxFilter: "",
      //statusFilter: "",

      //statusColumnFilter: "", // New data property for filtering by status
      loading: true,
      items: [],
      infoList: [],
      fields: [
        {
          key: "show_index",
          label: "#",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },

        { key: "stockNo" },
        { key: "yearMonth", label: "Year", sorter: false},
        {
          key: "arrivalStatus",
          label: "Arrival",sorter: false
        },
        {
          key: "stockStatus",
          _style: "max-width:150px;",
          label: "Status",sorter: false
        },
        // { key: "latestStatus" },
        // {
        //   key: "latest_stock_status",
        //   _style: "min-width:100px;",
        //   label: "Status",
        //   filter: false,
        // },
        // { key: "brandName" },
        // { key: "modelName" },
        // { key: "price" },
        { key: "brandModelName", label: "Model",sorter: false },
        { key: "price",  filter:false,sorter: false },
        { key: "vehicleDescription", label: "Description",sorter: false },

        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ],
      details: [],
      collapseDuration: 0,
      api: new StockApi(),
      warningModal: false,
      itemToDelete: {},
    };
  },
  mounted() {
    var self = this;
    self.refreshTable();
  },
  watch: {
    reloadParams() {
      this.refreshTable();
    },
  },

  computed: {
    reloadParams() {
      return [
        this.sorterValue,
        this.columnFilterValue,
        this.tableFilterValue,
        this.activePage,
        this.itemsPerPage,
      ];
    },
    computedCsvContent() {
      let index = 1;
      return this.statusColumnFilteredItems.map((item) => {
        return {
          index: index++,
          latestStatus: item.latestStatus,
          brandName: item.brandName,
          modelName: item.modelName,
          price: item.price,
        };
      });
    },
    computedCsvContentWithHeader() {
      const csvRows = [];

      // Add headers
      csvRows.push(
        ["No", "Latest Status", "Brand", "Model", "Price (RM)"].join(",")
      );

      // Add rows
      csvRows.push(
        ...this.computedCsvContent.map((item) => {
          return [
            item.index,
            item.latestStatus,
            item.brandName,
            item.modelName,
            item.price,
          ]
            .map((field) => `"${String(field).replace(/"/g, '""')}"`)
            .join(","); // Escape quotes and format CSV
        })
      );

      return csvRows.join("\r\n");
    },
    csvCode() {
      const BOM = "\uFEFF"; // UTF-8 BOM
      return (
        "data:text/csv;charset=utf-8," +
        encodeURIComponent(BOM + this.computedCsvContentWithHeader)
      );
    },
    statusColumnFilteredItems() {
      if (this.statusColumnFilter) {
        return this.computedItems.filter((item) => {
          return item.latestStatus
            .toLowerCase()
            .includes(this.statusColumnFilter.toLowerCase());
        });
      } else {
        return this.computedItems;
      }
    },
    computedItems() {
      return this.loadedItems.map((item) => {
        return {
          ...item,
          latestStatus: this.getLatesStockStatusName(item),
          brandName: this.getBrandName(item),
          modelName: this.getModelName(item),
          price: this.getPricing(item),
          brandModelName: this.getBrandModelName(item),
          vehicleDescription: this.getVehicleDescription(item),

          yearMonth: this.getMonthYear(item),
          arrivalStatus: this.getArrivalStatus(item),
          stockStatus: this.getStockStatus(item), // Add the new Status field
        };
      });
    },
  },
  methods: {
    onCleanerClick()
    {
      this.priceMinFilter = "";
      this.priceMaxFilter = "";
      this.columnFilterValue = {}; // Reset column-specific filters
      this.tableFilterValue = ""; // Reset global table filter
      this.refreshTable(); // Reload data or reset filtered items

    },
    getVehicleDescription(item) {
      try {
        return item.vehicle.description;
      } catch (error) {
        return "";
      }
    },
    getBrandModelName(item) {
      try {
        return item.vehicle.brand.name + " " + item.vehicle.model.name;
      } catch (error) {
        return "N/A";
      }
    },
    getArrivalBadge(status) {
      return status === "R" ? "success" : "warning";
    },
    getStockStatus(item) {
      let statusArray = [];
      if (item.isOpen) {
        statusArray.push("Open");
      }
      if (item.isLou) {
        statusArray.push("LOU");
      }
      if (item.isBooked) {
        statusArray.push("Booking");
      }
      if (item.isSold) {
        statusArray.push("Sold");
      }
      if (item.isCancelled) {
        statusArray.push("Cancelled");
      }

      // Join the status array into a single string separated by spaces
      var status = statusArray.length > 0 ? statusArray.join(" ") : "";
      return status;
    },
    getArrivalStatus(item) {
      if (item.arrivalStateDescription == "Received") return "R";
      else return "I";
    },
    getMonthYear(item) {
      var month = "";
      var year = "";
      try {
        month = item.vehicle.month;
      } catch (error) {}
      try {
        year = item.vehicle.year;
      } catch (error) {}

      return year + "/" + month;
    },
    // downloadCSV() {
    //   const csvData = this.convertToCSV(this.statusColumnFilteredItems);
    //   const blob = new Blob([csvData], { type: "text/csv" });
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "stock_data.csv";
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // },
    // convertToCSV(data) {
    //   const csvRows = [];

    //   // Get the headers
    //   const headers = Object.keys(data[0]);
    //   csvRows.push(headers.join(","));

    //   // Loop through each item and create a row
    //   for (const row of data) {
    //     const values = headers.map((header) => {
    //       const escaped = ("" + row[header]).replace(/"/g, '\\"'); // Escape quotes
    //       return `"${escaped}"`; // Wrap in double quotes
    //     });
    //     csvRows.push(values.join(","));
    //   }

    //   return csvRows.join("\n");
    // },

    // filterStatus(filterText) {
    //   console.log(filterText);
    //   // Custom logic to filter based on the input
    //   // this.fields.find(f => f.key === key).filterFunction = (value) => {
    //   //   return !filterText || value.toLowerCase().includes(filterText.toLowerCase());
    //   // };
    // },
    getImage(item) {
      if (item.vehicle == null) return "";
      if (item.vehicle.vehiclePhotoList == null) return "";
      if (item.vehicle.vehiclePhotoList.length == 0) return "";
      var url = "";
      url =
        apiUrl +
        "Documents/File/" +
        item.vehicle.vehiclePhotoList[0].documentId;
      return url;
    },
    getPricing(item) {
      if (item.pricing == null) return "N/A";
      if (item.pricing.recommendedSalePrice == 0) return "N/A";
      return new Intl.NumberFormat("ms-MY", {
        style: "currency",
        currency: "MYR",
      }).format(item.pricing.recommendedSalePrice);
    },
    getBrandName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.brand == null) return "N/A";
      return item.vehicle.brand.name;
    },
    getModelName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.model == null) return "N/A";
      return item.vehicle.model.name;
    },
    getLatesStockStatusName(item) {
      if (item.latestStockStatus == null) return "N/A";
      if (item.latestStockStatus.stockStatus == null) return "N/A";
      return item.latestStockStatus.stockStatus.name;
    },
    getBadge(status) {
      const statusColors = {
        Draft: "light",
        Registered: "primary",
        LOU: "primary",
        Shipped: "primary",
        ArriveAtPort: "primary",
        ShowRoom: "warning",
        Available: "warning",
        AVAILABLE: "warning",
        DUTY: "danger",
        "INCOMING STOCK": "light",
        Booked: "success",
        BOOKING: "success",
        Cancelled: "dark",
        Sold: "success",
      };

      return statusColors[status] || ""; // Return the color if found, otherwise return an empty string
    },
    // getBadge(status) {
    //   return status === "Draft"
    //     ? "primary"
    //     : status === "Registered"
    //     ? "secondary"
    //     : status === "Shipped"
    //     ? "warning"
    //     : status === "ArriveAtPort"
    //     ? "success"
    //     : status === "ShowRoom"
    //     ? "info"
    //     : status === "Available"
    //     ? "danger"
    //     : status === "Booked"
    //     ? "light"
    //     : status === "Cancelled"
    //     ? "dark"
    //     : "";
    // },

    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },

    refreshTable() {
      this.loading = true;

      const params = {
        page: this.activePage,
        itemsPerPage: this.itemsPerPage,

        sorter: this.sorterValue,
        filters: this.columnFilterValue,
        search: this.tableFilterValue,

        priceMin: this.priceMinFilter,
        priceMax: this.priceMaxFilter,
      };

      this.api
        .getList(params)
        .then((response) => {
          if (response.result) {
            this.loadedItems = response.result.items;
            this.pages = Math.ceil(response.result.totalItems / this.itemsPerPage);
            console.log("Loaded items:", this.loadedItems);
            console.log("Total pages:", this.pages);


          } else {
            this.loadedItems = [];
            this.pages = 1;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.toast("Error", "Failed to load data", "danger");
          this.loading = false;
        });
    },

    // refreshTable() {
    //   var self = this;
    //   const filters = {
    //     brand: this.priceMinFilter,
    //     model: this.priceMaxFilter,
    //     status: this.statusFilter,
    //   };

    //   self.loading = true;
    //   self.api
    //     .getListByFilter(filters)
    //     .then((response) => {
    //       self.items = response.result;
    //       self.loading = false;
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //       self.loading = false;
    //     });
    // },
    onEdit(item) {
      var self = this;
      self.$router.push({
        path: `/admins/Stock/${item.id}`,
      });
    },
    onDeleteConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .delete(self.itemToDelete.id)
          .then((response) => {
            self.refreshTable();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemToDelete = {};
    },
    showDeleteConfirmation(item) {
      var self = this;
      self.itemToDelete = item;
      self.warningModal = true;
    },
    addNew() {
      this.$router.push({ path: "/admins/Stock" });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  },
};
</script>

<style scoped>
.button-group {
  margin-top: 10px;
}
</style>
